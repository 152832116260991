/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from "three";

const audio = new Audio("/njanjanjanja.mp3");

export default function Beer(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF(
        "/telestras.gltf"
    );

    const { actions } = useAnimations(animations, group);
    //
    React.useEffect(() => {
        actions && actions['NlaTrack'].play();
    }, [actions]);
    

    const playBounceAnimation = () => {
        console.log("actions: ", actions);
        if(actions['Armature|mixamo.com|Layer0 Retarget.001'].isRunning()) {
            actions['Armature|mixamo.com|Layer0 Retarget.001'].stop();
            //play NlaTrack animation
            actions['NlaTrack'].play();
            //stop audio
            audio.pause();
            //reset audio
            audio.currentTime = 0;
        } else {
            audio.play();
            actions['Armature|mixamo.com|Layer0 Retarget.001'].play();
        }
    }


    return (
        <group ref={group} onClick={()=>playBounceAnimation()} {...props} dispose={null}>
            <group name="Scene">
                <group name="rig">
                    <group name="Dominik001">
                        <skinnedMesh
                            name="Basemesh_arshlevon_shoes001"
                            geometry={nodes.Basemesh_arshlevon_shoes001.geometry}
                            material={materials.Stras}
                            skeleton={nodes.Basemesh_arshlevon_shoes001.skeleton}
                        />
                        <skinnedMesh
                            name="Basemesh_arshlevon_shoes001_1"
                            geometry={nodes.Basemesh_arshlevon_shoes001_1.geometry}
                            material={materials.TeleMrfy_01}
                            skeleton={nodes.Basemesh_arshlevon_shoes001_1.skeleton}
                        />
                    </group>
                    <primitive object={nodes["DEF-spine"]} />
                    <primitive object={nodes["DEF-pelvisL"]} />
                    <primitive object={nodes["DEF-pelvisR"]} />
                    <primitive object={nodes["DEF-thighL"]} />
                    <primitive object={nodes["DEF-thighR"]} />
                    <primitive object={nodes["DEF-foreheadL"]} />
                    <primitive object={nodes["DEF-foreheadR"]} />
                    <primitive object={nodes["DEF-foreheadL001"]} />
                    <primitive object={nodes["DEF-foreheadR001"]} />
                    <primitive object={nodes["DEF-foreheadL002"]} />
                    <primitive object={nodes["DEF-foreheadR002"]} />
                    <primitive object={nodes["DEF-templeL"]} />
                    <primitive object={nodes["DEF-templeR"]} />
                    <primitive object={nodes["DEF-browBL"]} />
                    <primitive object={nodes["DEF-browBL001"]} />
                    <primitive object={nodes["DEF-browBL002"]} />
                    <primitive object={nodes["DEF-browBL003"]} />
                    <primitive object={nodes["DEF-lidBL"]} />
                    <primitive object={nodes["DEF-lidBL001"]} />
                    <primitive object={nodes["DEF-lidBL002"]} />
                    <primitive object={nodes["DEF-lidBL003"]} />
                    <primitive object={nodes["DEF-lidTL"]} />
                    <primitive object={nodes["DEF-lidTL001"]} />
                    <primitive object={nodes["DEF-lidTL002"]} />
                    <primitive object={nodes["DEF-lidTL003"]} />
                    <primitive object={nodes["DEF-browBR"]} />
                    <primitive object={nodes["DEF-browBR001"]} />
                    <primitive object={nodes["DEF-browBR002"]} />
                    <primitive object={nodes["DEF-browBR003"]} />
                    <primitive object={nodes["DEF-lidBR"]} />
                    <primitive object={nodes["DEF-lidBR001"]} />
                    <primitive object={nodes["DEF-lidBR002"]} />
                    <primitive object={nodes["DEF-lidBR003"]} />
                    <primitive object={nodes["DEF-lidTR"]} />
                    <primitive object={nodes["DEF-lidTR001"]} />
                    <primitive object={nodes["DEF-lidTR002"]} />
                    <primitive object={nodes["DEF-lidTR003"]} />
                    <primitive object={nodes["DEF-earL"]} />
                    <primitive object={nodes["DEF-earL001"]} />
                    <primitive object={nodes["DEF-earL002"]} />
                    <primitive object={nodes["DEF-earL003"]} />
                    <primitive object={nodes["DEF-earL004"]} />
                    <primitive object={nodes["DEF-earR"]} />
                    <primitive object={nodes["DEF-earR001"]} />
                    <primitive object={nodes["DEF-earR002"]} />
                    <primitive object={nodes["DEF-earR003"]} />
                    <primitive object={nodes["DEF-earR004"]} />
                    <primitive object={nodes["DEF-tongue"]} />
                    <primitive object={nodes["DEF-chin"]} />
                    <primitive object={nodes["DEF-chin001"]} />
                    <primitive object={nodes["DEF-chinL"]} />
                    <primitive object={nodes["DEF-chinR"]} />
                    <primitive object={nodes["DEF-jaw"]} />
                    <primitive object={nodes["DEF-jawL001"]} />
                    <primitive object={nodes["DEF-jawR001"]} />
                    <primitive object={nodes["DEF-tongue001"]} />
                    <primitive object={nodes["DEF-tongue002"]} />
                    <primitive object={nodes["DEF-cheekTL"]} />
                    <primitive object={nodes["DEF-browTL"]} />
                    <primitive object={nodes["DEF-browTL001"]} />
                    <primitive object={nodes["DEF-browTL002"]} />
                    <primitive object={nodes["DEF-browTL003"]} />
                    <primitive object={nodes["DEF-cheekTR"]} />
                    <primitive object={nodes["DEF-browTR"]} />
                    <primitive object={nodes["DEF-browTR001"]} />
                    <primitive object={nodes["DEF-browTR002"]} />
                    <primitive object={nodes["DEF-browTR003"]} />
                    <primitive object={nodes["DEF-jawL"]} />
                    <primitive object={nodes["DEF-jawR"]} />
                    <primitive object={nodes["DEF-nose"]} />
                    <primitive object={nodes["DEF-noseL"]} />
                    <primitive object={nodes["DEF-noseR"]} />
                    <primitive object={nodes["DEF-lipBL"]} />
                    <primitive object={nodes["DEF-lipBR"]} />
                    <primitive object={nodes["DEF-lipBL001"]} />
                    <primitive object={nodes["DEF-lipBR001"]} />
                    <primitive object={nodes["DEF-cheekBL001"]} />
                    <primitive object={nodes["DEF-cheekBR001"]} />
                    <primitive object={nodes["DEF-cheekBL"]} />
                    <primitive object={nodes["DEF-cheekBR"]} />
                    <primitive object={nodes["DEF-lipTL001"]} />
                    <primitive object={nodes["DEF-lipTR001"]} />
                    <primitive object={nodes["DEF-lipTL"]} />
                    <primitive object={nodes["DEF-lipTR"]} />
                    <primitive object={nodes["DEF-nose002"]} />
                    <primitive object={nodes["DEF-nose001"]} />
                    <primitive object={nodes["DEF-nose003"]} />
                    <primitive object={nodes["DEF-nose004"]} />
                    <primitive object={nodes["DEF-noseL001"]} />
                    <primitive object={nodes["DEF-noseR001"]} />
                    <primitive object={nodes["DEF-cheekTL001"]} />
                    <primitive object={nodes["DEF-cheekTR001"]} />
                    <primitive object={nodes["DEF-shoulderL"]} />
                    <primitive object={nodes["DEF-upper_armL"]} />
                    <primitive object={nodes["DEF-breastL"]} />
                    <primitive object={nodes["DEF-shoulderR"]} />
                    <primitive object={nodes["DEF-upper_armR"]} />
                    <primitive object={nodes["DEF-breastR"]} />
                </group>
            </group>
            </group>
    );
}

useGLTF.preload("/telestras.gltf");